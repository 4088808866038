import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { useForm } from 'react-hook-form'

import SubmitButton from './SubmitButton'
import { RegionContainer } from '../styles/Utility.styles'
import * as Styled from './styles/ResetPasswordForm.styles'
import {
  AuthForm,
  FormContainer,
  FieldGroup,
  FormHeader,
} from './styles/AuthFormCommon.styles'
import LabeledTextField from './LabeledTextField'
import { VALIDATION_RULES } from './validationRules'
import { useAuth } from '../Auth/useAuth'

const ResetPasswordForm = () => {
  const auth = useAuth()
  const { handleSubmit, register, setError, errors, watch } = useForm()
  const [wasCodeSent, setWasCodeSent] = useState(null)

  const onSubmit = values => {
    if (!wasCodeSent) {
      auth.forgotPassword(values.email).then(result => {
        if (result.code === 'UserNotFoundException') {
          setError('email', {
            type: 'manual',
            shouldFocus: true,
            message: 'No user exists for this email address.',
          })
        } else if (result.CodeDeliveryDetails) {
          setWasCodeSent(true)
        }
      })
    } else if (wasCodeSent) {
      auth
        .forgotPasswordSubmit(values.email, values.code, values.password)
        .then(() => {
          navigate('/health-care-provider-directory/login')
        })
    }
    console.log(values)
  }

  return (
    <RegionContainer>
      <FormContainer>
        <FormHeader>
          {!wasCodeSent
            ? 'Reset Your Password'
            : 'Check email for confirmation code'}
        </FormHeader>
        <AuthForm onSubmit={handleSubmit(onSubmit)}>
          <FieldGroup>
            <LabeledTextField
              label="Email"
              type="text"
              name="email"
              isRequired
              fieldWidth="47%"
              errors={errors.email}
              fieldRef={register(VALIDATION_RULES.email)}
            />
            {wasCodeSent && (
              <LabeledTextField
                label="Confirmation Code"
                type="text"
                name="code"
                isRequired
                fieldWidth="47%"
                errors={errors.code}
                fieldRef={register(VALIDATION_RULES.required)}
              />
            )}
          </FieldGroup>
          {wasCodeSent && (
            <FieldGroup>
              <LabeledTextField
                label="New Password"
                type="password"
                name="password"
                isRequired
                fieldWidth="47%"
                errors={errors.password}
                fieldRef={register(VALIDATION_RULES.password)}
              />
              <LabeledTextField
                label="Confirm Password"
                type="password"
                name="confirmPassword"
                isRequired
                fieldWidth="47%"
                errors={errors.confirmPassword}
                fieldRef={register({
                  ...VALIDATION_RULES.password,
                  validate: value =>
                    value === watch('password')
                      ? true
                      : 'Passwords do not match',
                })}
              />
            </FieldGroup>
          )}

          <Styled.CtaRow>
            <Styled.BackToLogin to="/health-care-provider-directory/login/">
              Back to Login
            </Styled.BackToLogin>
            <SubmitButton
              type="submit"
              text={!wasCodeSent ? 'Send Code' : 'Submit'}
            />
          </Styled.CtaRow>
        </AuthForm>
      </FormContainer>
    </RegionContainer>
  )
}

export default ResetPasswordForm
