import React from 'react'
import { graphql } from 'gatsby'

import HcpdFormPageHoc from '../../components/HealthCareProviderDirectory/HcpdFormPageHoc'
import ResetPasswordForm from '../../components/Auth/ResetPasswordForm'
import { ProvideAuth } from '../../components/Auth/useAuth'

export const query = graphql`
  {
    prismicHcpdResetPasswordPage {
      data {
        body_text {
          richText
        }
        title {
          text
        }
      }
    }
  }
`

const ResetPasswordPage = ({ data }) => {
  const doc = data.prismicHcpdResetPasswordPage?.data
  if (!doc) {
    return null
  }

  return (
    <ProvideAuth>
      <HcpdFormPageHoc doc={doc}>
        <ResetPasswordForm />
      </HcpdFormPageHoc>
    </ProvideAuth>
  )
}

export default ResetPasswordPage
